/* eslint-disable */
import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DownloadButtons from "../../components/downloadButtons";
// import phones from '../../images/iphones.png'
import "./style.scss";
import { getUser, useLogout } from "../../utils/auth";
import { isUserSubscribed } from "../../utils/revenueCat";
import { getSubscription, cancelSubscription, getPortalSession } from "../../utils/stripe";
import { navigate } from "gatsby-link";
import { head as _head, filter as _filter, isEmpty as _isEmpty } from "lodash";
import BRAND_CONFIG from '../../BRAND_CONFIG';
import HeaderLogo from "../../components/header/HeaderLogo";
import SubscriptionStateMessages from "../../components/SubscriptionStateMessages/SubscriptionStateMessages";
import Loading from "../../components/loading";

const ManageSubscriptionPage = () => {
  // TODO: Refactor to use context
  const { name, logo } = BRAND_CONFIG;
  const [state, setState] = React.useState({
    isFetchingSubscriptions: true,
    activeSubscription: null,
    subscriptions: {},
    stripeSubscription: null,
    confirmCancelStripe: false,
    stripeSubscriptionCancelled: false,
    stripeSubscriptionCancelFailed: false,
  });

  React.useEffect(() => {
    hasSubscriptions();
  }, []);

  const logout = useLogout();

  const hasSubscriptions = async () => {
    let user = null;
    try {
      user = await getUser();
    } catch (e) {
      console.log(e);
      console.log("go back to join");
      navigate(`/register`);
    }
    try {
      // If customer has never had a subscription, or it's not active, redirect to subscribe page
      const subscription = await isUserSubscribed(user.username);
      if (!subscription || !subscription?.is_active) navigate(`/subscribe`);

      let stripeSubscription = null;
      let portalSession = null;

      if (subscription?.store === "web") {
        const getSubscriptionResponse = await getSubscription();
        stripeSubscription = getSubscriptionResponse?.data ?? null;
        stripeSubscription = _isEmpty(stripeSubscription) ? null : stripeSubscription;
        try{
          const getPortalSessionResponse = await getPortalSession();
          portalSession = getPortalSessionResponse?.data ?? null;
          console.log("portal", getPortalSessionResponse);
        }catch(e){
          console.log("Could not fetch portal session");
          console.log(e);
          portalSession = null;
        }
      }

      setState({
        ...state,
        isFetchingSubscriptions: false,
        activeSubscription: subscription,
        stripeSubscription,
        portalSession,
        profile: user.attributes,
      });
    } catch (e) {
      console.log("Could not fetch subscriptions");
      console.log(e);
    }
  };

  const onLogout = async () => {
    logout();
    setState({ ...state, isAuthenticated: false });
    window.location.href = "/";
  };

  const onOpenStripePortal = () => (window.location.href = state.portalSession.url);

  return (
    <Layout>
      <SEO title="Manage Subscription" />
      <div className="page-wrapper">
            {state.isFetchingSubscriptions && <Loading />}
            {!state.isFetchingSubscriptions && (
              <>
                <Col />
                <Col className="text-center mt-3 flex-sm-fill flex--grow-1" style={{width: '500px'}}>
                  <HeaderLogo title={`${BRAND_CONFIG?.pages?.manageSubscription?.translations?.headingPreText ?? 'You have an active'} ${state?.activeSubscription?.store} ${BRAND_CONFIG?.pages?.manageSubscription?.translations?.headingMidText ?? 'subscription for'} ${name}`} logo={logo} logoSize={100} />
              
                  <p >{BRAND_CONFIG?.pages?.manageSubscription?.translations?.usernameReminder ?? 'Your user is'} {state?.profile?.email ?? ""}</p>
                     <DownloadButtons />
                 
                    <p>{BRAND_CONFIG?.pages?.manageSubscription?.translations?.startedOn ?? 'Started on'} {new Date(state?.activeSubscription?.purchase_date).toLocaleString()}</p>
                    <p>{BRAND_CONFIG?.pages?.manageSubscription?.translations?.periodFinishes ?? 'Current period finishes on'} {new Date(state?.activeSubscription?.expires_date).toLocaleString()}</p>
                    
                    <SubscriptionStateMessages 
                          state={state} 
                          onOpenStripePortal={onOpenStripePortal}
                      />

<Row className="info-row mt-5 d-flex align-items-sm-end">
                    <Col>
                      <p className="text-center body-font">
                        <a onClick={onLogout}>               
                            {BRAND_CONFIG?.pages?.manageSubscription?.translations?.logout ?? 'Log Out'}
                        </a>
                      </p>
                    </Col>
                </Row>
                    
                </Col>
                </>
            )}
      </div >
    </Layout>
  );
};

export default ManageSubscriptionPage;
