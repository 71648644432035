import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import BRAND_CONFIG from '../../BRAND_CONFIG';

const SubscriptionStateMessages = ({
    state, onOpenStripePortal,
}) => (
    <Row className="d-flex justify-content-center align-items-center">
        <Col className=" d-flex flex-column  justify-content-center ">
            {state?.activeSubscription?.unsubscribe_detected_at && (
                <p>{BRAND_CONFIG?.pages?.subscriptionPage?.translations?.cancelConfirmation ?? 'You have cancelled the subscription. It will end at the end of this period.'}</p>
            )}
            {state?.activeSubscription?.store === 'web'
                && !state?.activeSubscription?.unsubscribe_detected_at
                && state?.stripeSubscription && (

                <Button type="button" className="btn btn-warning" onClick={onOpenStripePortal}>
                    Manage your subscription
                </Button>

            )}
            {state?.activeSubscription?.store === 'web'
                && !state?.activeSubscription?.unsubscribe_detected_at
                && !state?.confirmCancelStripe
                && !state?.stripeSubscription && (
                <p>
                    {BRAND_CONFIG?.pages?.subscriptionPage?.translations?.cancelWebPreText ?? 'If you subscribed via website contact with our'}
                    {' '}
                    <a href={BRAND_CONFIG.links.support}>{BRAND_CONFIG?.pages?.subscriptionPage?.translations?.cancelWebMidText ?? 'support center'}</a>
                    .
                </p>
            )}
            {state?.stripeSubscriptionCancelled && (
                <p>{BRAND_CONFIG?.pages?.subscriptionPage?.translations?.cancellationDelayLongText ?? 'Your subscription has been cancelled. It can take up to 2 hours to be shown here.'}</p>
            )}
            {state?.stripeSubscriptionCancelFailed && (
                <p className="danger-text">{BRAND_CONFIG?.pages?.subscriptionPage?.translations?.cancellationError ?? 'There has been an error cancelling the subscription. Please, contact our support center'}</p>
            )}
            {state?.activeSubscription?.store !== 'web' && (
                <p>
                    {BRAND_CONFIG?.pages?.manageSubscription?.translations?.appStoreCancelText ?? 'If you subscribed via the App Store or Play Store and you wish to cancel your current subscription you will need to do so from within the respective store.'}
                </p>
            )}
        </Col>
    </Row>
);

export default SubscriptionStateMessages;
